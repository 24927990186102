import { AXIOS_INSTANCE } from "../service";
import {
  CRICKET_BASE_URL,
  SOCCER_BASE_URL,
  TENNIS_BASE_URL,
  HORCE_RACING_BASE_URL,
} from "./../utils/config";
export const getToken = () => {
  return localStorage.getItem("token");
};
export const allSeries = async (flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-matches-admin?isSettled=${flag}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allVertialSeries = async (flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-virtual-matches-admin?isSettled=${flag}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allmatchesBySeriesId = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/events/allmatches?seriesId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getMatchDetailById = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/events/match/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/events/SeriesEnabling?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/market-enable-disable?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesBetEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/events/SeriesBetEnabling?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const matchBetEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/match-bet-lock?matchId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketBetEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/market-bet-lock?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const matchEnableDisabled = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/match-enable-disable?eventId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allMarkets = async (id, page) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/all-markets?matchId=${id}&start=${page}&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMarketMaxMinBet = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/market-max-min-bet?marketId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setSeriesMaxMinBet = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/events/SeriesSetMaxMinBet?seriesId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMatchMaxMinBet = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/match-max-min-bet?matchId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarket = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL.replace("cricket", "")}bet/settleMarket`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketToss = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_BASE_URL.replace("cricket", "")}bet/settleMarketToss`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getDefaultMaxMin = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${CRICKET_BASE_URL}/events/getDefaultMaxMin`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setDefaultMaxMin = async (max, min) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/events/setDefaultMaxMin?max=${max}&min=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketFancy = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${CRICKET_BASE_URL.replace("cricket", "")}bet/settleMarketFancy`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const updateRunnerOdds = async (payload, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${CRICKET_BASE_URL}/events/updateRunnerOdds?subMarketId=${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

//soccer
export const allSeriesSoccer = async (flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/all-matches-admin?isSetteled=${flag}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allmatchesBySeriesIdSoccer = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/events/allmatches?seriesId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getMatchDetailByIdSoccer = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/events/match/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/series-enable-disable?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/events/MarketEnabling?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesBetEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/series-bet-lock?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const matchBetEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/match-bet-lock?matchId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketBetEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/events/MarketBetEnabling?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const matchEnableDisabledSoccer = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/match-enable-disable?eventId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allMarketsSoccer = async (id, page) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/all-markets?matchId=${id}&start=${page}&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMarketMaxMinBetSoccer = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/market-max-min-bet?marketId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setSeriesMaxMinBetSoccer = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/series-max-min-bet?seriesId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMatchMaxMinBetSoccer = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/match-max-min-bet?matchId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketSoccer = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL.replace("soccer", "")}bet/soccer/settleMarket`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleOtherMarketSoccer = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL.replace("soccer", "")}bet/soccer/settleMarket/other`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getDefaultMaxMinSoccer = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${SOCCER_BASE_URL}/events/getDefaultMaxMin`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setDefaultMaxMinSoccer = async (max, min) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL}/events/setDefaultMaxMin?max=${max}&min=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketFancySoccer = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${SOCCER_BASE_URL.replace("soccer", "")}bet/soccer/settleMarket`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

//tennis
export const allSeriesTennis = async (flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/all-matches-admin?isSetteled=${flag}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allmatchesBySeriesIdTennis = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/events/allmatches?seriesId=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getMatchDetailByIdTennis = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/events/match/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/series-enable-disable?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/events/MarketEnabling?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const seriesBetEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/series-bet-lock?seriesId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const matchBetEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/match-bet-lock?matchId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const marketBetEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/market-bet-lock?marketId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

export const matchEnableDisabledTennis = async (id, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/match-enable-disable?eventId=${id}&flag=${flag}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allMarketsTennis = async (id, page) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/all-markets?matchId=${id}&start=${page}&limit=20`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setSeriesMaxMinBetTennis = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/series-max-min-bet?seriesId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMatchMaxMinBetTennis = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/match-max-min-bet?matchId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setMarketMaxMinBetTennis = async (max, min, id) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/market-max-min-bet?marketId=${id}&maxBet=${max}&minBet=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketTennis = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL.replace("tennis", "")}bet/tennis/settleMarket`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getDefaultMaxMinTennis = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${TENNIS_BASE_URL}/events/getDefaultMaxMin`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const setDefaultMaxMinTennis = async (max, min) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL}/events/setDefaultMaxMin?max=${max}&min=${min}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketFancyTennis = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${TENNIS_BASE_URL.replace("tennis", "")}bet/tennis/settleMarket`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};

//hac
export const createMasterEvent = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${HORCE_RACING_BASE_URL}/hac/events/createMasterEvent`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getDefaultMaxMinHAC = async () => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/getDefaultMaxMin`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const validateMasterEventsHAC = async (query) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/validateMasterEvents/${query}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const createChildEventHAC = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${HORCE_RACING_BASE_URL}/hac/events/createChildEvent`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const addRunnerHAC = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.post(
      `${HORCE_RACING_BASE_URL}/hac/events/addRunner`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const allSeriesAdminHAC = async (flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/allSeriesAdmin?isSetteled=${flag}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const getChildEventByIdHAC = async (id) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/childEvent/${id}`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const updateRunnerHAC = async (id, payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/updateRunner/${id}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        },
      }
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const validateRunnerHAC = async (
  eventId,
  horseName,
  jokeyName,
  jokeyNumber
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/validateRunner/${eventId}?horseName=${horseName}&jokeyName=${jokeyName}&jokeyNumber=${jokeyNumber}`
    );
    return data;
  } catch (err) {
    return err;
  }
};

// date format 2000-10-31T01:30:00.000
export const validateChildEventHAC = async (
  competitionId,
  eventName,
  startDate
) => {
  try {
    const { data } = await AXIOS_INSTANCE.get(
      `${HORCE_RACING_BASE_URL}/hac/events/validateChildEvents/${competitionId}/${eventName}/${startDate}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const childEventInplayHAC = async (eventId, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/childEventInplay?eventId=${eventId}&flag=${flag}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const masterEventBetEnablingHAC = async (eventId, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/masterEventBetEnabling?eventId=${eventId}&flag=${flag}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const masterEventEnablingHAC = async (eventId, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/masterEventEnabling?eventId=${eventId}&flag=${flag}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const childEventBetEnablingHAC = async (eventId, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/childEventBetEnabling?eventId=${eventId}&flag=${flag}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const childEventEnablingHAC = async (eventId, flag) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/childEventEnabling?eventId=${eventId}&flag=${flag}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const masterEventSetMaxMinBetHAC = async (max, min, eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/masterEventSetMaxMinBet?eventId=${eventId}&maxBet=${max}&minBet=${min}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const childEventSetMaxMinBetHAC = async (max, min, eventId) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/hac/events/childEventSetMaxMinBet?eventId=${eventId}&maxBet=${max}&minBet=${min}`
    );
    return data;
  } catch (err) {
    return err;
  }
};
export const settleMarketHAC = async (payload) => {
  try {
    const { data } = await AXIOS_INSTANCE.put(
      `${HORCE_RACING_BASE_URL}/bet/settleMarket`,
      payload
    );
    return data;
  } catch (err) {
    return err;
  }
};
